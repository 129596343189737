import { printerService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ResourceStatusEnum } from '@/resource/enum';
import { PrinterList } from '@/resource/model';
import {
  dateFormat,
  messageError,
  translation,
  showWarningConfirm,
  getStatusI18Key,
  getStatusClass
} from '@/utils';
import { MessageBox, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AddPrinter from './add-printer/add-printer.vue';
@Component({
  components: { AddPrinter }
})
export default class Printer extends mixins(PagingMixin) {
  public tableOptions: OsTableOption<PrinterList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };

  public queryOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'system:printer:save',
      handleClick: this.openPrinterDialog
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'system:printer:batchDelete',
      plain: true,
      disabled: true,
      handleClick: this.batchDeletePrinter
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'system:printer:editStatus',
      handleClick: (): void => {
        this.batchUpdatePrinterStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'system:printer:editStatus',
      handleClick: (): void => {
        this.batchUpdatePrinterStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  public columnOptions: Array<OsTableColumn<PrinterList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'name',
      label: 'printer.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'model',
      label: 'printer.model',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'ip',
      label: 'printer.ip',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'port',
      label: 'printer.port',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return dateFormat((rowData as PrinterList).createTime);
      }
    }
  ];

  public rowOperationOptions: RowOperation<PrinterList> = {
    fixed: 'right',
    width: '200px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:printer:edit',
        handleClick: (rowData: PrinterList): void => {
          this.openPrinterDialog(rowData);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'system:printer:delete',
        handleClick: (rowData: PrinterList): void => {
          this.deletePrinter(rowData.id);
        }
      }
    ]
  };

  public queryForm = {
    keywords: ''
  };

  public printer: PrinterList | null = null;

  public printerDialogVisible = false;

  private selectedRows: Array<PrinterList> = [];

  public created(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.selectedRows = [];
    (this.$refs.printerTable as OsTable).clearSelection();
    this.loadData();
  }

  public loadData(): void {
    this.tableOptions.loading = true;
    printerService
      .getList(this.queryForm)
      .then(res => {
        this.tableOptions.data = res.data ?? [];
        this.totalData = res.total ?? 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public handleSelectionChange(selectedData: Array<PrinterList>): void {
    this.selectedRows = selectedData;
  }

  public editPrinter(data: PrinterList): void {
    const findItem = this.tableOptions.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public closeAddPrinterDialog(): void {
    this.printerDialogVisible = false;
    this.printer = null;
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  private openPrinterDialog(printer?: PrinterList): void {
    this.printer = printer ?? null;
    this.printerDialogVisible = true;
  }

  private deletePrinter(id: number): void {
    MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    })
      .then(async () => {
        try {
          this.tableOptions.loading = true;
          await printerService.delete(id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.tableOptions.loading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeletePrinter(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          this.tableOptions.loading = true;
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await printerService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.tableOptions.loading = false;
          this.selectedRows = [];
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private batchUpdatePrinterStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    printerService
      .batchUpdatePrinterStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('selectedRows')
  private handleSelectChanged(value: Array<PrinterList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }
}
