import { printerService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { PrinterList, PrinterSave } from '@/resource/model';
import { translation, messageError } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddPrinter extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public printer!: PrinterList | null;

  public dialogVisible = true;

  public printerForm: Partial<PrinterSave> = {
    name: '',
    model: '',
    ip: '',
    port: undefined,
    remark: ''
  };

  public printerFormRules: { [p in keyof PrinterSave]: Array<object> } = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('printer.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          printerService
            .checkPrinterProp(value, this.printer?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    if (!this.printer) {
      this.title = 'printer.addPrinter';
      return;
    }
    this.title = 'printer.editPrinter';
    Object.assign(this.printerForm, this.printer);
  }

  public submit(): void {
    (this.$refs.printerForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (!this.printer) {
          await printerService.post(this.printerForm);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await printerService.put(this.printerForm);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.printerForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
